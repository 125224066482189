<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <asom-card>
        <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            :label="'Is Maintainance Staff Informed'"
            :state="inputStates('formData.isMaintainanceStaffInformed')"
            error="Please Inform Maintainane Staff"
          >
            <asom-input-checkbox
              v-model="formData.isMaintainanceStaffInformed"
              label="Is Maintainance Staff Informed"
              description="Check if Maintainance Staff is Informed"
            />
          </asom-form-field>
          <asom-form-field
            required
            label="Description"
            :state="inputStates('formData.description')"
            error="Description is required"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.description"
              :state="inputStates('formData.description')"
              :maxlength="1000"
            />
          </asom-form-field>
          <div></div>
          <asom-form-field
            required
            label="Remarks"
            :state="inputStates('formData.remarks')"
            error="Remarks is required"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.remarks"
              :state="inputStates('formData.remarks')"
              :maxlength="1000"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            label="Supporting documents"
            :state="inputStates('formData.files')"
            error="Supporting documents are required"
          >
            <asom-upload
              v-model="formData.files"
              :category="attachmentCategories.FAULT_MANAGEMENT"
              :state="inputStates('formData.files')"
            />
            <asom-upload-file-list
              :files="formData.uploadedFiles"
              disabled-remove
            />
          </asom-form-field>
          <div class="col-span-2 flex justify-end space-x-4 pt-8">
            <asom-button
              text="Back"
              variant="secondary"
              @click="$router.go(-1)"
            />
            <asom-button
              text="Update"
              @click="updateStationFaultClick"
              :loading="isSubmitting"
            />
          </div>
        </form>
      </asom-card>
    </article>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import {
  editStationFault,
  getStationFaultDetails,
} from "../../../services/faultManagement.service";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      error: null,
      isSubmitting: false,
      formData: {
        description: null,
        isMaintainanceStaffInformed: false,
        files: [],
        uploadedFiles: [],
        remarks: null,
      },
    };
  },
  validations() {
    return {
      formData: {
        //isMaintainanceStaffInformed: { required, sameAs: sameAs(true) },
        remarks: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
    }),
    workCentreOptions() {
      return [
        { label: "COM", value: 1 },
        { label: "AFC", value: 2 },
      ];
    },
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
  },
  mounted() {
    this.getFaultDetails();
  },
  methods: {
    async getFaultDetails() {
      const result = await getStationFaultDetails({
        faultId: get(this.$route.params, "faultId"),
        stationId: this.stationId,
      });
      if (result.success) {
        this.setFormData(get(result.payload, "model"));
      }
    },
    setFormData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      };
      let attachments = get(data, "attachments", []);
      this.formData.uploadedFiles = attachments.map((attachment) => {
        attachment.fileId = attachment.attachmentId;
        return attachment;
      });
    },
    updateStationFaultClick() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      let uploadedIds = [];
      if (this.formData.files) {
        uploadedIds = this.formData.files.map((file) => file.fileId);
      }
      if (this.formData.uploadedFiles) {
        uploadedIds.concat(
          this.formData.uploadedFiles.map((file) => file.fileId)
        );
      }
      this.isSubmitting = true;
      const result = await editStationFault({
        faultId: get(this.$route.params, "faultId"),
        description: this.formData.description,
        isMaintainanceStaffInformed: this.formData.isMaintainanceStaffInformed,
        remarks: this.formData.remarks,
        attachments: uploadedIds,
      });
      if (result.success) {
        this.isSubmitting = false;
        this.$router.go(-1);
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
  },
};
</script>
